<template>
  <div
    :class="[isSelected ? ['border', 'border-info'] : '']"
    class="card check check-lg check-purple check-unchecked cursor-pointerh h-100"
  >
    <div class="card-body">
      <div class="row pb-2 px-2">
        <img
          :src="require(`@/assets/images/${logo}`)"
          :alt="snakeBrand"
          height="50px"
        />
      </div>

      <div
        class="font-weight-bold text-dark text-right pb-2 d-flex justify-content-between font-lg"
      >
        <div v-for="(numbers, index) in hiddenCardNumbers" :key="index">
          {{ "* ".repeat(numbers).trim() }}
        </div>

        <div>
          {{ creditCard.last4 }}
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="text-purple-dark font-weight-semibold text-smallcaps">
            <translate>Card holder</translate>
          </div>
          {{ creditCard.name }}
        </div>
        <div class="col-auto ml-auto text-right">
          <div class="text-purple-dark font-weight-semibold text-smallcaps">
            <translate>Expiration date</translate>
          </div>
          {{ `${creditCard.exp_month}/${creditCard.exp_year}` }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    creditCard: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    hiddenCardNumbersMap: {
      visa: [4, 4, 4],
      mastercard: [4, 4, 4],
      american_express: [4, 6, 1]
    }
  }),
  computed: {
    ...mapGetters("creditCards", ["selected"]),
    hiddenCardNumbers() {
      return this.hiddenCardNumbersMap[this.snakeBrand];
    },
    snakeBrand() {
      return this.creditCard.brand
        .toLowerCase()
        .split(" ")
        .join("_");
    },
    logo() {
      return `${this.snakeBrand}.svg`;
    },
    isSelected() {
      return this.creditCard.id === this.selected.id;
    }
  }
};
</script>
