<template>
  <div>
    <ContentHeader :title="$gettext('My Cart')">
      <template #description>
        <div>
          {{
            $gettext(
              "*Please note that all of our plans renew automatically and can be canceled at any time."
            )
          }}
        </div>
      </template>
    </ContentHeader>
    <h4 class="mt-0">
      <translate v-show="!addNewCard"
        >Choose one of your stored credit cards</translate
      >
      <translate v-show="addNewCard">Add a new credit card</translate>
    </h4>
    <div class="row">
      <div class="col-md-8">
        <div v-if="!addNewCard" class="row mb-3">
          <div
            @click="select(card)"
            class="col-md-4 mb-4"
            v-for="(card, index) in creditCards"
            :key="index"
          >
            <credit-card-card @click="select(card)" :credit-card="card" />
          </div>
          <div class="col-md-4 mb-4" @click="toggleAddNewCard">
            <add-credit-card-card></add-credit-card-card>
          </div>
        </div>
        <div v-else class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <CreditCardForm
                  :cancellable="canCancelAddNewCard"
                  @success="toggleAddNewCard"
                  @cancel="toggleAddNewCard"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <div class="card-header">
            <h5 class="card-title"><translate>Payment details</translate></h5>
          </div>
          <div class="card-body">
            <form @submit.prevent="handleApplyCoupon">
              <div class="row">
                <div class="col-md-12">
                  <FormInput label="Coupon" field="coupon" v-model="coupon" />
                </div>
                <div class="col-md-6">
                  <SubmitButton
                    class="btn btn-info"
                    :label="$gettext('Apply Coupon')"
                  />
                </div>
              </div>
            </form>
            <hr />
            <div class="row">
              <div class="col-md-12">
                <p class="card-text">
                  {{
                    $gettext(
                      "The amount of an existing subscription is prorated over the month."
                    )
                  }}
                </p>
                <h6 class="card-subtitle mb-2 text-muted">
                  {{ $gettext("Amount added to your next billing") }}
                </h6>
              </div>
            </div>
            <hr />
            <div class="row mb-3">
              <div class="col-6">{{ $gettext("Subtotal") }}:</div>
              <div class="col-6 text-right mb-0 font-weight-bold">
                {{ rawSubTotal }}$
              </div>
            </div>
            <div v-if="hasCoupon" class="row mb-3">
              <div class="col-6">
                {{ $gettext("Coupon") }}:
                <span class="text-success">{{ couponData.id }}</span>
              </div>
              <div class="col-6 text-right font-weight-bold mb-0">
                -{{ discount }}$
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-6">{{ $gettext("TPS") }}:</div>
              <div class="col-6 text-right font-weight-bold mb-0">
                {{ tpsAmount }}$
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-6">{{ $gettext("TVQ") }}:</div>
              <div class="col-6 text-right font-weight-bold mb-0">
                {{ tvqAmount }}$
              </div>
            </div>
            <div class="row">
              <div class="col-6 font-weight-bold">{{ $gettext("Total") }}:</div>
              <div class="col-6 text-right font-weight-bold mb-0">
                <div>{{ total }}$</div>
                <small v-if="getStripeInterval === 'year'">
                  <translate>*Billed annually</translate>
                </small>
              </div>
            </div>
            <hr />
            <form @click.prevent="handlePay({ credit_card: selected, coupon })">
              <div class="row">
                <div class="col-md-12">
                  <SubmitButton
                    :disabled="disablePayButton"
                    class="btn btn-block btn-info"
                    :label="$gettext('Confirm')"
                  ></SubmitButton>
                </div>
                <pre>{{ response }}</pre>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import CreditCardForm from "@/views/Boutique/components/CreditCardForm";
import CreditCardCard from "@/views/Boutique/components/CreditCardCard";
import AddCreditCardCard from "@/views/Boutique/components/AddCreditCardCard";
import FormInput from "@/components/FormGroups/FormInput";
import SubmitButton from "@/components/SubmitButton";
import ContentHeader from "@/components/ContentHeader";

export default {
  components: {
    SubmitButton,
    FormInput,
    CreditCardForm,
    CreditCardCard,
    AddCreditCardCard,
    ContentHeader
  },
  data: () => ({
    pk: process.env.VUE_APP_STRIPE_PK,
    coupon: "",
    addNewCard: false
  }),
  async created() {
    this.clearCoupon();
    await this.fetchAll();
    await this.initCart();
    this.stopFetching();
  },
  computed: {
    ...mapGetters("form", ["errors"]),
    ...mapGetters("creditCards", ["hasCreditCards", "creditCards", "selected"]),
    ...mapGetters("cart", [
      "redirectPath",
      "rawSubTotal",
      "discount",
      "subTotal",
      "hasCoupon",
      "tvqAmount",
      "tpsAmount",
      "total",
      "response",
      "getStripeInterval",
      "isEmpty"
    ]),
    ...mapGetters({ couponData: "cart/coupon" }),
    canCancelAddNewCard() {
      return this.creditCards.length > 0;
    },
    noCreditCardSelected() {
      return Object.keys(this.selected).length === 0;
    },
    disablePayButton() {
      return this.noCreditCardSelected || this.isEmpty;
    }
  },
  methods: {
    ...mapActions("creditCards", ["create", "fetchAll"]),
    ...mapActions("form", ["submit"]),
    ...mapActions("cart", {
      pay: "pay",
      getCoupon: "getCoupon",
      initCart: "init"
    }),
    ...mapMutations("creditCards", ["select"]),
    ...mapMutations("cart", ["setCoupon", "clearCoupon"]),
    ...mapMutations("app", ["startFetching", "stopFetching"]),
    handleApplyCoupon() {
      this.submit(() => this.getCoupon(this.coupon));
    },
    handlePay(payload) {
      this.submit(() =>
        this.pay({
          ...payload,
          stripe_interval: this.getStripeInterval
        })
      ).then(async ({ data: paymentIntent }) => {
        if (paymentIntent.status === "requires_action") {
          this.startFetching();
          const res = await this.$stripe.confirmCardPayment(
            paymentIntent.client_secret
          );
          this.stopFetching();
          if (res.error) {
            this.$toast.error(res.error.message);
          } else {
            this.$toast.info(
              "Confirmation succeeded! Your subscription might take a few minutes to update."
            );
          }
        }
        if (this.redirectPath) {
          await this.$router.push({ path: this.redirectPath });
        } else {
          await this.$router.push({ name: "subscription" });
        }
      });
    },
    toggleAddNewCard() {
      this.addNewCard = !this.addNewCard;
    }
  }
};
</script>
